/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// // To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'brickyard-ui/dist/brickyard-ui.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../styles/shared/portal-customisations.scss';
import 'core-js/stable';

import { I18n } from 'i18n-js';
import translations from './../../assets/javascripts/i18n/translations.json';
window.I18n = new I18n(translations);

import React from 'react';
import ReactDOM from 'react-dom';

// Expose React and ReactDOM globally
window.React = React;
window.ReactDOM = ReactDOM;

/* eslint-disable */
const scenesContext = require.context('components/scenes', true);
import ReactRailsUJS from 'react_ujs';
/* eslint-enable */
window.ReactRailsUJS = ReactRailsUJS;

ReactRailsUJS.useContext(scenesContext);
